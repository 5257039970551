import React from 'react';
import '../../styles.scss';
import { images } from '../../../../../../../../../library/common/constants/ImageConstants';
import Slider from 'react-slick';
import _ from 'lodash';
import { CurrencyFormat } from 'utility';
class TicketSold extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let listOfItemGroupByWinnerId = _.chain(this.props?.feturesData?.item.filter(item => item.winnerId !== null))
      ?.groupBy('winnerId')
      .map((value, key) => ({
        isAnonymousContribution: value[0].winner.isAnonymousContribution,
        winnerName: value[0].winnerId !== null ? value[0].winner.firstName + ' ' + (value[0].winner.lastName ? value[0].winner.lastName : "") : '',
        sum: _.sumBy(value, element => {
          // console.log('value', value);
          return Number(
            element?.isSold === true && element?.markAsPaid === true
              ? element?.buyItNow !== null
                ? element?.buyItNow
                : element?.currentBid
              : element?.currentBid,
          );
        }),
      }))
      .value();

    return (
      <div>
        <Slider
          dots={false}
          infinite={true}
          speed={0}
          autoplay={true}
          autoplaySpeed={4000}
          swipe={false}
          touchMove={false}
          arrows={false}
          pauseOnHover={false}>
          {listOfItemGroupByWinnerId &&
            listOfItemGroupByWinnerId?.map((item, i) => (
              <div className="ticketSold">
                <div>
                  <img className="me-2" src={images.auctionWallPriceImg} alt="" style={{ display: 'inline-flex' }} />{' '}
                  {/* {item?.winner?.firstName} {item?.winner?.lastName}{' '} */}
                  <div style={{ display: 'inline-flex', textTransform: 'capitalize' }}>
                    {item?.isAnonymousContribution ? 'Anonymous Contributor' : item?.winnerName}
                  </div>{' '}
                  {'donated'}{' '}
                  {/* {item?.providedBy && item?.providedBy == 'sponsoredBy'
                  ? 'sponsored'
                  : item?.providedBy == 'donatedBy'
                  ? 'donated'
                  : 'provided'}{' '} */}
                  {/* {this.props.currency}
                {item?.isSold === true && item?.buyItNow !== null ? item?.buyItNow : item?.currentBid} */}
                  <CurrencyFormat
                    value={item?.sum || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={
                      this.props.eventDetails && this.props.eventDetails.currencyId !== null
                        ? this.props.currencyAbbreviation + ' ' + this.props.currencySymbol
                        : ''
                    }
                    thousandSpacing={this.props.currencySymbol === '₹' ? '2s' : '3'}
                    // thousandSpacing="2s"
                    decimalScale="2"
                    fixedDecimalScale={true}
                    className="currency-fromate"
                  />
                </div>
              </div>
            ))}
        </Slider>
      </div>
    );
  }
}
export default TicketSold;
